import React from "react";

/* ICON IMPORTS */
import Mail from "../assets/icons/mail.svg";
import Call from "../assets/icons/call.svg";
import MapMarker from "../assets/icons/map-marker.svg";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { FaInstagram, FaThreads, FaYoutube } from "react-icons/fa6";
import InspiroworldLogo from "../assets/logos/Lieve-White.png";
import { FaIndianRupeeSign } from "react-icons/fa6";
import { MdCancel, MdLocalShipping } from "react-icons/md";

function Footer() {
  const navigate = useNavigate();
  const user = useSelector((state) => state?.user);
  return (
    <>
      {" "}
      <div className="default-services">
        <div className="service-block">
          <div className="icon-bg">
            <MdLocalShipping className="support-icon" />
          </div>
          <span className="support-text">Free Shipping</span>
        </div>
        <div className="service-block">
          <div className="icon-bg">
            <FaIndianRupeeSign className="support-icon" />
          </div>
          <span className="support-text">Easy Payments</span>
        </div>
        {/* <div className="service-block">
          <div className="icon-bg">
            <MdCancel className="support-icon" />
          </div>
          <span className="support-text">NO Returns</span>
        </div> */}
      </div>
      <div className="footer">
        <div className="container">
          <div className="footer-top">
            <div className="container">
              <div className="footer-logo">
                <img
                  src={InspiroworldLogo}
                  alt="Inspiroworld"
                  // className="footer-logo"
                />
              </div>
              <div className="row">
                {/* CALL TO ACTION */}

                <div className="col-lg-4 col-sm-12 extra-padding">
                  <div className="single-wedge">
                    <h4 className="footer-heading">GET IN TOUCH!</h4>
                    <div className="footer-links">
                      <a className="last-wedge" href="mailto:info@lieve.care">
                        <img
                          src={Mail}
                          width={23}
                          alt="Reach Begiesvia Email"
                        />
                        support@begies.in
                      </a>
                      <a className="last-wedge" href="/#">
                        <img
                          src={Call}
                          width={23}
                          alt="Reach Begiesvia Call"
                        />
                        <span>
                          {/* Office Hours
                        <br />
                        9:00am-7:00pm (IST)
                        <br /> */}
                          {/* <a href="https://wa.me/9499777708">
                          Whatsapp: +91-9499 7777 08
                        </a> */}
                          <a href="tel:+919499777708">
                            Phone: +91-9499 7777 08
                          </a>
                        </span>
                      </a>
                      <a
                        className="last-wedge"
                        target="_blank"
                        href="https://goo.gl/maps/dLimtEnWG3DoCWxs9"
                        rel="noreferrer"
                      >
                        <img
                          src={MapMarker}
                          width={23}
                          alt="Reach Begiesvia Location"
                        />
                        Patel nagar main road
                        <br />
                        Near 40 feet road
                        <br />
                        Omnagar circle
                        <br />
                        Rajkot 360004
                      </a>
                    </div>
                  </div>
                </div>
                {/* LEARN MORE */}
                <div className="col-lg-3 col-sm-12">
                  <div className="single-wedge">
                    <h4 className="footer-heading">LEARN MORE</h4>
                    <div className="footer-links">
                      <a onClick={() => navigate("/about-us")}>About Us</a>
                      {/* <a href="/shipping-return-policy">
                                            Shipping &amp; Return Policy
                                        </a> */}
                      <a onClick={() => navigate("/privacy-policy")}>
                        Privacy Policy
                      </a>
                      <a onClick={() => navigate("/terms-conditions")}>
                        Terms &amp; Conditions
                      </a>
                      <a onClick={() => navigate("/contact-us")}>Contact Us</a>
                    </div>
                  </div>
                </div>
                {/* HERE WE ARE TO HELP YOU */}
                <div className="col-lg-3 col-sm-12 responsive-margin">
                  <div className="single-wedge">
                    <h4 className="footer-heading">
                      HERE WE ARE TO
                      <br />
                      HELP YOU!
                    </h4>
                    <div className="footer-links">
                      <a
                        onClick={() =>
                          user?.token
                            ? navigate("/my-account")
                            : navigate("/register")
                        }
                        // href={
                        //     user?.token
                        //         ? "/my-account"
                        //         : "/register"
                        // }
                      >
                        My Account
                      </a>
                      <a
                        onClick={() =>
                          user?.token
                            ? navigate("/my-account")
                            : navigate("/register")
                        }
                      >
                        Order History
                      </a>
                      {/* <a href="/rewards">Rewards</a> */}
                    </div>
                  </div>
                </div>
                {/* STORE */}
                <div className="col-lg-2 col-sm-12 responsive-margin">
                  <div className="single-wedge">
                    <h4 className="footer-heading">STORE</h4>
                    <div className="footer-links">
                      <a onClick={() => navigate("/wholesale")}>Wholesale</a>

                      <a onClick={() => navigate("/products")}>
                        All Collections
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-bottom">
            <div className="container">
              <div className="line-shape-top">
                <div className="row">
                  <div className="col-lg-4">
                    <p className="copyright-text">
                      © 2024 Begies.in Inc, All Rights Reserved.
                    </p>
                  </div>
                  <div className="col-lg-4 logo-div">
                    <FaInstagram
                      onClick={() =>
                        window.open(
                          "https://www.instagram.com/lieve.care?utm_source=qr&igsh=MWRuZHAzaWc3YXpucg== ",
                          "_blank"
                        )
                      }
                      className="social-logo"
                    />
                    <FaThreads
                      onClick={() =>
                        window.open(
                          "https://www.threads.net/@lieve.care",
                          "_blank"
                        )
                      }
                      className="social-logo"
                    />
                    <FaYoutube
                      onClick={() =>
                        window.open(
                          "https://youtube.com/@lievecare?si=MHdBBowvBWW2bV-8",
                          "_blank"
                        )
                      }
                      className="social-logo"
                    />
                  </div>
                  <div className="col-lg-4">
                    <p
                      className="made-line"
                      style={{
                        marginBottom: "0px",
                        fontFamily: "Mulish, sans-serif",
                        textAlign: "end",
                        color: "#A1A1A1",
                      }}
                    >
                      Powered by{" "}
                      <a
                        href="https://softweirdo.com/"
                        target="blank"
                        style={{
                          textDecoration: "none",
                          color: "#A1A1A1",
                          fontWeight: "700",
                        }}
                      >
                        SoftWeirdo Solution
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
