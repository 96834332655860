import React, { useState, useRef } from "react";
// import {Button} from "react-bootstrap"
import Carousel from "react-bootstrap/Carousel";
import SliderLeft from "../assets/icons/slider-left.svg";
import SliderRight from "../assets/icons/slider-right.svg";
import { useNavigate } from "react-router-dom";

import desktop01 from "../assets/NewPoster/desktop01.jpg";
import desktop02 from "../assets/NewPoster/desktop02.jpg";
import desktop03 from "../assets/NewPoster/desktop03.jpg";
import desktop04 from "../assets/NewPoster/desktop04.jpg";
import mobile01 from "../assets/mobileBanner/new1.jpg";
import mobile02 from "../assets/mobileBanner/new2.jpg";
import mobile03 from "../assets/mobileBanner/new3.jpg";
import mobile04 from "../assets/mobileBanner/new4.jpg";
import mobile05 from "../assets/mobileBanner/new5.jpg";
import mobile06 from "../assets/mobileBanner/new6.jpg";
import { Button } from "react-bootstrap";

// import SeasonalMobileBanner from "../assets/banners/new/Body wash.jpg"
// import SeasonalMobilePowder from "../assets/banners/new/04.jpg"
// import powder from "../assets/banners/new/031.jpg"
// import lieve from "../assets/banners/new/0111.jpg"
// import SeasonalMobileBanner from "../assets/banners/seasonal-banner-mobile-2.png"
// import WholesaleBanner from "../assets/banners/05.jpg"
// import FrontBanner from "../assets/banners/front-banner-02.webp"
// import FrontMobileBanner from "../assets/banners/front-banner-mobile.png"
function HeadBanner() {
  const [index, setIndex] = useState(0);
  const navigate = useNavigate();
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };
  // const showOnMobile = document.innerWidth < 650
  //
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  const showOnMobile = windowSize?.current?.[0] < 769;

  // console.info(windowSize?.current?.[0])
  //
  return (
    <section
      className="banner-section"
      style={{ height: "30px", background: "#000" }}
    >
      <Carousel
        className="banner"
        nextIcon={<img src={SliderRight} alt="Slider Icon" />}
        prevIcon={<img src={SliderLeft} alt="Slider Icon" />}
        activeIndex={index}
        onSelect={handleSelect}
        controls={false}
        indicators={false}
      >
        <Carousel.Item interval={1500}>
          {/* {!showOnMobile ? (
            <img
              src={desktop01}
              // style={{maxWidth: "100%", maxHeight: "100%"}}
              alt="Lieve"
            />
          ) : (
            <img
              src={mobile01}
              // style={{maxWidth: "100%", maxHeight: "100%"}}
              alt="Lieve"
            />
          )} */}
          <div className="container">
            <div className="row mb-50">
              <div className="col-xl-12 col-sm-12 mt-1">
                <h6 className="text-center" style={{ color: "#fff" }}>
                  🚚 Free delivery on orders above ₹500! 🎉
                </h6>
              </div>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item interval={1500}>
          {/* {!showOnMobile ? (
            <img
              src={desktop01}
              // style={{maxWidth: "100%", maxHeight: "100%"}}
              alt="Lieve"
            />
          ) : (
            <img
              src={mobile01}
              // style={{maxWidth: "100%", maxHeight: "100%"}}
              alt="Lieve"
            />
          )} */}
          <div className="container">
            <div className="row mb-50">
              <div className="col-xl-12 col-sm-12 mt-1">
                <h6 className="text-center" style={{ color: "#fff" }}>
                  Flash Sale! Grab Your Favorites at Unbeatable Prices!
                </h6>
              </div>
            </div>
          </div>
        </Carousel.Item>

        <Carousel.Item interval={1500}>
          {/* <img src={!showOnMobile ? SeasonalMobileBanner : lieve} alt="Lieve" /> */}
          {/* {!showOnMobile ? (
            <img src={desktop02} alt="Lieve" />
          ) : (
            <img src={mobile02} alt="Lieve" />
          )} */}
          <div className="container">
            <div className="row">
              <div className="col-xl-12 col-sm-12 mt-1">
                <h6 className="text-center" style={{ color: "#fff" }}>
                  Clearance Sale: Up to 70% Off – Don’t Miss Out!
                </h6>
              </div>
            </div>
          </div>
        </Carousel.Item>
        {/* <Carousel.Item interval={1500}>
          {!showOnMobile ? (
            <img src={desktop03} alt="Lieve" />
          ) : (
            <img src={mobile03} alt="Lieve" />
          )}
        </Carousel.Item>
        <Carousel.Item interval={1500}>
          {!showOnMobile ? (
            <img src={desktop04} alt="Lieve" />
          ) : (
            <img src={mobile05} alt="Lieve" />
          )}
        </Carousel.Item> */}
        {/* {showOnMobile && (
          <Carousel.Item>
            <img src={mobile04} alt="Lieve" />
          </Carousel.Item>
        )} */}
        {/* {showOnMobile && (
          <Carousel.Item>
            <img src={mobile05} alt="Lieve" />
          </Carousel.Item>
        )} */}
        {/* {showOnMobile && (
          <Carousel.Item>
            <img src={mobile06} alt="Lieve" />
          </Carousel.Item>
        )} */}
        {/* <Carousel.Item>
          <img
            src={showOnMobile ? SeasonalMobileBanner : SeasonalBanner}
            style={{maxWidth: "100%", maxHeight: "100%"}}
            alt="Inspiroworld International"
          />
          <Carousel.Caption>
            <div className="container">
              <div className="row mb-50 justify-content-end">
                <div
                  className={
                    `col-xl-6 col-sm-5` + (showOnMobile ? "mobile-banner" : "")
                  }>
                  <div
                    className="hero-slide-content wholesale-textbox third-banner bg-white"
                    id="banner-2">
                    <h2 className="title-1">SPRING</h2>
                    <h2 className="title-1">COLLECTION</h2>
                    <h6>The right fabric for the season</h6>
                    <Button
                      className={
                        showOnMobile
                          ? "view-collection responsive-button"
                          : "view-collection"
                      }
                      onClick={() => {
                        navigate(`/products`)
                      }}>
                      VIEW COLLECTION
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </Carousel.Caption>
        </Carousel.Item> */}
      </Carousel>
    </section>
  );
}

export default HeadBanner;
