/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-useless-escape */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import * as yup from "yup";
import { Link } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { wholeSale } from "../service/api";
import Loader from "react-spinners/HashLoader";
import SpinnerComponent from "../components/Spinner";

import ThankYouGIF from "../assets/icons/order-placed.gif";

function Wholesale() {
    const { enqueueSnackbar } = useSnackbar();
    const [thankYouModal, setThankYouModal] = useState(false);

    const validationSchema = yup.object().shape({
        firstName: yup.string().required(),
        lastName: yup.string().required(),
        email: yup.string().email().required(),
        mobile: yup.number().required(),
        // articleNo: yup.string().required(),
        fabricName: yup.string().required(),
        fabricColor: yup.string().required(),
        quantity: yup.string().required(),
    });

    const [loading, setLoading] = useState(false);
    const [submit, setSubmit] = useState(false);

    const formik = useFormik({
        initialValues: {
            firstName: "",
            lastName: "",
            email: "",
            message: null,
            mobile: null,
            fabricName: "",
            articleNo: "",
            fabricColor: "",
            quantity: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const obj = {
                // articleNo: values.articleNo ? values.articleNo : "null",
                email: values.email,
                fabricColor: values.fabricColor,
                fabricName: values.fabricName,
                firstName: values.firstName,
                lastName: values.lastName,
                quantity: values.quantity.toString(),
            };
            if (values?.articleNo) {
                obj.articleNo = values.articleNo;
            }
            if (values?.message) {
                obj.message = values.message;
            }
            if (values?.mobile) {
                obj.mobile = values.mobile.toString();
            }
            setLoading(true);
            wholeSale(obj)
                .then((res) => {
                    enqueueSnackbar(res?.message, { variant: "success" });
                    if (res?.status) {
                        formik.setFieldValue("firstName", "");
                        formik.setFieldValue("lastName", "");
                        formik.setFieldValue("email", "");
                        formik.setFieldValue("message", "");
                        formik.setFieldValue("mobile", "");
                        formik.setFieldValue("fabricName", "");
                        formik.setFieldValue("articleNo", "");
                        formik.setFieldValue("fabricColor", "");
                        formik.setFieldValue("quantity", 0);
                        setSubmit(false);
                        setThankYouModal(!thankYouModal);
                    }
                })
                .catch((e) => {
                    console.log("error--->", e);
                    enqueueSnackbar("Something went wrong. Please try again", {
                        variant: "error",
                    });
                })
                .finally((res) => {
                    setLoading(false);
                });
        },
    });

    return (
        <div className="fabric-page">
            {/* BANNER */}
            <div className="wholesale-banner">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-12">
                            <h1>BUY PRODUCT IN WHOLESALE AT BEGIES</h1>
                        </div>
                    </div>
                </div>
            </div>

            {/* WHOLESALE FORM */}
            <div className="wholesale-bg-section">
                {loading ? (
                    <SpinnerComponent />
                ) : (
                    <div className="container">
                        <div className="col-xl-12 col-sm-12">
                            <h2>
                                Interested in bulk buying at great price… <br />
                                Fill in the form below and our team will get
                                back to you!!
                            </h2>

                            <div className="row mt-4">
                                <form onSubmit={formik.handleSubmit}>
                                    <div className="row">
                                        <div className="col-xl-6 col-sm-12">
                                            <div className="form-group mb-3">
                                                <input
                                                    value={
                                                        formik.values.firstName
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    type="text"
                                                    className="form-control opacity-form-control"
                                                    id="first-name"
                                                    name="firstName"
                                                    placeholder="First Name *"
                                                />
                                                <small
                                                    className="validation-error"
                                                    style={{ color: "red" }}
                                                >
                                                    {submit &&
                                                    formik.errors.firstName ===
                                                        "firstName is a required field"
                                                        ? "First Name is required"
                                                        : ""}
                                                </small>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-sm-12">
                                            <div className="form-group mb-3">
                                                <input
                                                    value={
                                                        formik.values.lastName
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    type="text"
                                                    className="form-control opacity-form-control"
                                                    id="last-name"
                                                    name="lastName"
                                                    placeholder="Last Name *"
                                                />
                                                <small
                                                    className="validation-error"
                                                    style={{ color: "red" }}
                                                >
                                                    {submit &&
                                                    formik.errors.lastName ===
                                                        "lastName is a required field"
                                                        ? "Last Name is required"
                                                        : ""}
                                                </small>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-sm-12">
                                            <div className="form-group mb-3">
                                                <input
                                                    value={formik.values.email}
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    type="email"
                                                    className="form-control opacity-form-control"
                                                    id="email"
                                                    name="email"
                                                    placeholder="Email Address *"
                                                />
                                                <small
                                                    className="validation-error"
                                                    style={{ color: "red" }}
                                                >
                                                    {submit &&
                                                    formik.errors.email ===
                                                        "email is a required field"
                                                        ? "Email is required"
                                                        : ""}
                                                </small>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-sm-12">
                                            <div className="form-group mb-3">
                                                <input
                                                    value={formik.values.mobile}
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    type="number"
                                                    className="form-control opacity-form-control"
                                                    id="phone"
                                                    name="mobile"
                                                    placeholder="Mobile Number *"
                                                />
                                                <small
                                                    className="validation-error"
                                                    style={{ color: "red" }}
                                                >
                                                    {submit &&
                                                    formik.errors.mobile
                                                        ? "Mobile is required"
                                                        : ""}
                                                </small>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-sm-12">
                                            <div className="form-group mb-3">
                                                <input
                                                    value={
                                                        formik.values.fabricName
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    type="text"
                                                    className="form-control opacity-form-control"
                                                    id="fabricName"
                                                    name="fabricName"
                                                    placeholder="Product Name*"
                                                />
                                                <small
                                                    className="validation-error"
                                                    style={{ color: "red" }}
                                                >
                                                    {submit &&
                                                    formik.errors.fabricName ===
                                                        "fabricName is a required field"
                                                        ? "Product name is required"
                                                        : ""}
                                                </small>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-sm-12">
                                            <div className="form-group mb-3">
                                                <input
                                                    value={
                                                        formik.values.articleNo
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    type="text"
                                                    className="form-control opacity-form-control"
                                                    id="articleNumber"
                                                    name="articleNo"
                                                    placeholder="Size (Not for wipes)"
                                                />
                                                {/* <small
                          className="validation-error"
                          style={{color: "red"}}>
                          {submit &&
                          formik.errors.articleNo ===
                            "articleNo is a required field"
                            ? "Article No is required"
                            : ""}
                        </small> */}
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-sm-12">
                                            <div className="form-group mb-3">
                                                <input
                                                    value={
                                                        formik.values.quantity
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    type="number"
                                                    className="form-control opacity-form-control"
                                                    id="fabricQuantity"
                                                    name="quantity"
                                                    placeholder="Quantity *"
                                                />
                                                <small
                                                    className="validation-error"
                                                    style={{ color: "red" }}
                                                >
                                                    {submit &&
                                                    formik.errors.quantity ===
                                                        "quantity is a required field"
                                                        ? "Quantity is required"
                                                        : ""}
                                                </small>
                                            </div>
                                        </div>
                                        <div className="col-xl-6 col-sm-12">
                                            <div className="form-group mb-3">
                                                <input
                                                    value={
                                                        formik.values
                                                            .fabricColor
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    type="text"
                                                    className="form-control opacity-form-control"
                                                    id="fabricColor"
                                                    name="fabricColor"
                                                    placeholder="Product Variant *"
                                                />
                                                <small
                                                    className="validation-error"
                                                    style={{ color: "red" }}
                                                >
                                                    {submit &&
                                                    formik.errors
                                                        .fabricColor ===
                                                        "fabricColor is a required field"
                                                        ? "Variant is required"
                                                        : ""}
                                                </small>
                                            </div>
                                        </div>
                                        <div className="col-lg-12 col-sm-12">
                                            <div className="input-style mb-20">
                                                <textarea
                                                    value={
                                                        formik.values.message
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    className="textarea-control opacity-form-control"
                                                    name="message"
                                                    id="message"
                                                    placeholder="Message (Optional)"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <Button
                                        onClick={() => setSubmit(true)}
                                        type="submit"
                                        className="submit-btn"
                                        style={{
                                            border: "1px solid #000",
                                            marginLeft: "0px",
                                        }}
                                    >
                                        Send message
                                    </Button>
                                    {/* SUBMIT POPUP */}
                                    <Modal
                                        className="wishlist-modal"
                                        centered
                                        show={thankYouModal}
                                        onHide={() => {
                                            setThankYouModal(!thankYouModal);
                                        }}
                                    >
                                        <Modal.Header
                                            className="wholesale-modal-header"
                                            closeButton
                                        >
                                            <Modal.Title className="wholesale-modal-title">
                                                Details have been received.
                                            </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body className="wholesale-modal-body">
                                            <img
                                                src={ThankYouGIF}
                                                width="100"
                                                alt="Form submitted"
                                            />
                                            <div className="thank-you-message">
                                                <h3>Thank You</h3>
                                                <p>
                                                    Your message has been
                                                    received and we will be
                                                    contacting you within 1-2
                                                    business days. If you would
                                                    like to speak to us
                                                </p>
                                                <p className="mt-1">
                                                    Reach us at{" "}
                                                    <a
                                                        href="https://wa.me/9499777708"
                                                        target="_blank"
                                                        style={{
                                                            textDecoration:
                                                                "none",
                                                            fontWeight: "700",
                                                        }}
                                                    >
                                                        +91 (949) 9777708
                                                    </a>{" "}
                                                    via Whatsapp or Phone.
                                                </p>
                                            </div>

                                            <div className="social-media-handles mt-4">
                                                <h5>FOLLOW US</h5>
                                                <div
                                                    className="d-flex mt-3"
                                                    style={{
                                                        alignItems: "center",
                                                        justifyContent:
                                                            "center",
                                                    }}
                                                >
                                                    <a
                                                        href="3"
                                                        style={{
                                                            color: "#000",
                                                        }}
                                                        target="_blank"
                                                    >
                                                        <div className="social-media-logo">
                                                            <svg
                                                                stroke="currentColor"
                                                                fill="currentColor"
                                                                stroke-width="0"
                                                                viewBox="0 0 320 512"
                                                                height="1.3em"
                                                                width="1.3em"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path d="M279.14 288l14.22-92.66h-88.91v-60.13c0-25.35 12.42-50.06 52.24-50.06h40.42V6.26S260.43 0 225.36 0c-73.22 0-121.08 44.38-121.08 124.72v70.62H22.89V288h81.39v224h100.17V288z"></path>
                                                            </svg>
                                                        </div>
                                                    </a>
                                                    <a
                                                        href="https://www.instagram.com/lieve.care?utm_source=qr&igsh=MWRuZHAzaWc3YXpucg=="
                                                        target="_blank"
                                                        style={{
                                                            color: "#000",
                                                        }}
                                                    >
                                                        <div className="social-media-logo">
                                                            <svg
                                                                stroke="currentColor"
                                                                fill="currentColor"
                                                                stroke-width="0"
                                                                viewBox="0 0 448 512"
                                                                height="1.3em"
                                                                width="1.3em"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
                                                            </svg>
                                                        </div>
                                                    </a>
                                                    <a
                                                        href="#"
                                                        target="_blank"
                                                        style={{
                                                            color: "#000",
                                                        }}
                                                    >
                                                        <div className="social-media-logo">
                                                            <svg
                                                                stroke="currentColor"
                                                                fill="currentColor"
                                                                stroke-width="0"
                                                                viewBox="0 0 16 16"
                                                                height="1.3em"
                                                                width="1.3em"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                            >
                                                                <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z"></path>
                                                            </svg>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                    </Modal>
                                </form>
                            </div>
                        </div>
                    </div>
                )}
            </div>

            {/* <div className="wholesale-details-form pt-50 pb-50">
        <div className="container">
          <div className="row">
            <div className="col-xl-12 col-sm-12">
              <h2>Please fill in the details.</h2>
              <form>
                <div className="row mt-4">
                  <div className="col-xl-6 col-sm-12">
                    <div className="form-group mb-3">
                      <input
                        onChange={(e) => {
                          setInput({
                            ...input,
                            [e.target.name]: e.target.value,
                          });
                        }}
                        type="text"
                        className="form-control"
                        id="first-name"
                        name="firstName"
                        placeholder="First Name"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-sm-12">
                    <div className="form-group mb-3">
                      <input
                        onChange={(e) => {
                          setInput({
                            ...input,
                            [e.target.name]: e.target.value,
                          });
                        }}
                        type="text"
                        className="form-control"
                        id="last-name"
                        name="lastName"
                        placeholder="Last Name"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-sm-12">
                    <div className="form-group mb-3">
                      <input
                        onChange={(e) => {
                          setInput({
                            ...input,
                            [e.target.name]: e.target.value,
                          });
                        }}
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        placeholder="Email Address"
                      />
                    </div>
                  </div>
                  <div className="col-xl-6 col-sm-12">
                    <div className="form-group mb-3">
                      <input
                        onChange={(e) => {
                          setInput({
                            ...input,
                            [e.target.name]: e.target.value,
                          });
                        }}
                        type="number"
                        className="form-control"
                        id="phone"
                        name="mobile"
                        placeholder="Phone Number (Optional)"
                      />
                    </div>
                  </div>
                  <div className="col-xl-4 col-sm-12">
                    <div className="form-group mb-3">
                      <input
                        onChange={(e) => {
                          setInput({
                            ...input,
                            [e.target.name]: e.target.value,
                          });
                        }}
                        type="text"
                        className="form-control"
                        id="fabricName"
                        name="fabricName"
                        placeholder="Fabric Name"
                      />
                    </div>
                  </div>
                  <div className="col-xl-4 col-sm-12">
                    <div className="form-group mb-3">
                      <input
                        onChange={(e) => {
                          setInput({
                            ...input,
                            [e.target.name]: e.target.value,
                          });
                        }}
                        type="text"
                        className="form-control"
                        id="articleNumber"
                        name="articleNo"
                        placeholder="Article Number"
                      />
                    </div>
                  </div>
                  <div className="col-xl-4 col-sm-12">
                    <div className="form-group mb-3">
                      <input
                        onChange={(e) => {
                          setInput({
                            ...input,
                            [e.target.name]: e.target.value,
                          });
                        }}
                        type="text"
                        className="form-control"
                        id="fabricColor"
                        name="fabricColor"
                        placeholder="Fabric Color"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12 col-sm-12">
                    <div className="input-style mb-20">
                      <textarea
                        onChange={(e) => {
                          setInput({
                            ...input,
                            [e.target.name]: e.target.value,
                          });
                        }}
                        className="textarea-control"
                        name="message"
                        id="message"
                        placeholder="Message"
                      />
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="submit-btn"
                    onClick={postWholesale}
                  >
                    Send message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div> */}
        </div>
    );
}

export default Wholesale;
