import React, { useEffect } from "react";
import {
    HashRouter as Router,
    Routes,
    Route,
    useLocation,
} from "react-router-dom";
import { ThemeProvider } from "react-bootstrap";
import { Provider } from "react-redux";
import store from "../redux/store";
import { SnackbarProvider } from "notistack";
import { ToastContainer } from "react-toast";

/* PAGE IMPORTS */
import Homepage from "../pages/Homepage";
import FabricContent from "../pages/shop-by-content/Fabric";
import Registration from "../pages/Registration";
import Cart from "../pages/Cart";
import ProductDetail from "../pages/ProductDetail";
import Checkout from "../pages/Checkout";
import ContactUs from "../pages/ContactUs";
import MyAccount from "../pages/MyAccount";
import Sale from "../pages/Sale";
import Wholesale from "../pages/Wholesale";
import CheckoutOptions from "../pages/CheckoutOptions";
import ProductList from "../pages/productList";
import OrderPlaced from "../pages/OrderPlaced";
import OrderCancelled from "../pages/OrderCancelled";
import OrderDetails from "../pages/OrderDetails";
import Rewards from "../pages/Rewards";
import AboutUs from "../pages/miscellaneous/AboutUs";
import ShippingReturnPolicy from "../pages/miscellaneous/ShippingPolicy";
import TermsConditions from "../pages/miscellaneous/TermsConditions";
import Layout from "../components/Layout";
import PrivacyPolicy from "../pages/miscellaneous/PrivacyPolicy";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
/* STYLES */
import "bootstrap/dist/css/bootstrap.min.css";
import "../styles/global.css";
import "react-quill/dist/quill.snow.css";
import NothingFound from "../pages/404";

function ScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    return null;
}

function App() {
    return (
        <Provider store={store}>
            <SnackbarProvider
                maxSnack={1}
                autoHideDuration={3000}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <ThemeProvider
                    breakpoints={["xl", "lg", "md", "sm"]}
                    minBreakpoint="sm"
                >
                    <div className="App">
                        <ToastContainer position="top-left" />
                        <Router>
                            <ScrollToTop />
                            <Layout>
                                <Routes>
                                    <Route path="/" element={<Homepage />} />
                                    <Route path="/sale" element={<Sale />} />
                                    <Route
                                        path="/wholesale"
                                        element={<Wholesale />}
                                    />
                                    {/* <Route path="/products" element={<FabricPage />} /> */}
                                    <Route
                                        path="/products"
                                        element={<ProductList />}
                                    />
                                    <Route
                                        path="/products/:id"
                                        element={<ProductList />}
                                    />
                                    <Route
                                        path="/wishlist"
                                        element={<FabricContent />}
                                    />
                                    <Route
                                        path="/register"
                                        element={<Registration />}
                                    />
                                    <Route path="/cart" element={<Cart />} />
                                    <Route
                                        path="/checkout"
                                        element={<Checkout />}
                                    />
                                    <Route
                                        path="/product/"
                                        element={<ProductDetail />}
                                    />
                                    <Route
                                        path="/product/:id"
                                        element={<ProductDetail />}
                                    />
                                    <Route
                                        path="/contact-us"
                                        element={<ContactUs />}
                                    />
                                    <Route
                                        path="/my-account"
                                        element={<MyAccount />}
                                    />
                                    <Route
                                        path="/checkout-options"
                                        element={<CheckoutOptions />}
                                    />
                                    <Route
                                        path="/order-cancle"
                                        element={<OrderCancelled />}
                                    />
                                    <Route
                                        path="/order-details"
                                        element={<OrderDetails />}
                                    />
                                    <Route
                                        path="/checkout-options"
                                        element={<CheckoutOptions />}
                                    />
                                    <Route
                                        path="/order-success"
                                        element={<OrderPlaced />}
                                    />
                                    {/* <Route
                                        path="/rewards"
                                        element={<Rewards />}
                                    /> */}

                                    {/* MISCELLANEOUS PAGES */}
                                    <Route
                                        path="/about-us"
                                        element={<AboutUs />}
                                    />
                                    <Route
                                        path="/shipping-return-policy"
                                        element={<ShippingReturnPolicy />}
                                    />
                                    <Route
                                        path="/privacy-policy"
                                        element={<PrivacyPolicy />}
                                    />
                                    <Route
                                        path="/terms-conditions"
                                        element={<TermsConditions />}
                                    />
                                    <Route
                                        path="/404"
                                        element={<NothingFound />}
                                    />
                                    <Route
                                        path="*"
                                        exact={true}
                                        element={<NothingFound />}
                                    />
                                </Routes>
                            </Layout>
                        </Router>
                    </div>
                </ThemeProvider>
            </SnackbarProvider>
        </Provider>
    );
}

export default App;
